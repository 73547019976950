.HeaderBGSlider{
    width: 100vw;
    height: 400px;
    margin-left: -10px;
  
    .HeaderItemsGroup{
      width: 100%;
      height: 100%;
      position: relative;
  
      .item{
        top: 0px;
        left: 0px;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.5s;
        visibility: hidden;
        position: relative;
        padding: 50px 20px;
        position: absolute;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .block{
          margin-top: 20px;
        }
  
        &:hover{
          
          // .bg{
          //   opacity: 0.2;
          // }
          
          .block{
            box-shadow: 0px 0px 50px #333;
          }
        }
  
        &.active{
          opacity: 1;
          visibility: visible;
        }
  
        .bg{
          top: 0px;
          left: 0px;
          opacity: 1;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.5s;
          position: absolute;
          transform: scale(1.03);
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
        }
        
        .blur{
          top: 0px;
          left: 0px;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.5s;
          filter: blur(5px);
          position: absolute;
          transform: scale(1.03);
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
        }
  
        .block{
          width: 100%;
          height: 100%;
          padding: 20px;
          color: #ffffff;
          transition:box-shadow 0.5s;
          max-width: 300px;
          overflow: hidden;
          max-height: 450px;
          border-radius: 10px;
          transform: scale(1.03);
          box-sizing: border-box;
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
          box-shadow: none;
          
          .circleLight{
            top: 0px;
            left: 0px;
            opacity: 0;
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            transition: 0.5s;
            position: absolute;
            border-radius: 10px;
            background: radial-gradient(circle at 80px 40px, #fff, transparent);
          }
          
          .text{
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            background-position: center;
            background-attachment: fixed;
          }
          
          h2{
            font-family: 'Oswald', sans-serif;
            font-size: 130px;
            margin-bottom: 0px;
          }
          
          p{
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
          }
          
          &:hover{
            box-shadow: 0px 0px 70px #111;
            
            .circleLight{
              opacity: 0.4;
            }
          }
        }
      }
    }
  
    .navigations{
      bottom: 0px;
      width: 100%;
      position: absolute;
  
      .dots{
        height: 20px;
        padding: 10px 0px;
        text-align: center;
  
        li{
          width: 10px;
          height: 10px;
          cursor: pointer;
          transition: 0.3s;
          background: #fff;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          
          &+li{
            margin-left: 10px;
          }
  
          &:hover{
            filter: blur(2px);
          }
  
          &.active{
            width: 15px;
            height: 15px;
            
            &:hover{
              filter: blur(0px);
            }
          }
        }
      }
    }
  }