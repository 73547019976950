.APIError {
    position: fixed;
    width: 100%;
    height: 300px;
    top: calc(50% - 150px);
    text-align: center;
}

.APIError span {
    font-size: 150px;
}