.timeline{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.timeline-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.timeline-item {
    display: flex;
}


.timeline-item-odd {
    flex-direction: row-reverse;
}

.timeline-item-odd .timeline-heading {
    text-align: left;
}


.timeline-item-odd .subheading {
    text-align: left;
}

.timeline-item-odd .text-muted {
    text-align: left;
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {
    .timeline-item-odd .timeline-panel {
        padding-left: 20px !important;
    }
}